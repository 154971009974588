import { Component, Input } from '@angular/core';
import {
  ItemPageFieldComponent
} from '../../../../../../../../app/item-page/simple/field-components/specific-field/item-page-field.component';

@Component({
  selector: 'ds-item-page-rights-field',
  templateUrl: './item-page-rights-field.component.html'
})
/**
 * This component is used for displaying the rights (dc.rights) of an item
 */
export class ItemPageRightsFieldComponent extends ItemPageFieldComponent {

  /**
   * The target on which the link needs to be opened.
   */
  @Input() target = '_blank';

  /**
   * Separator string between multiple values of the metadata fields defined
   * @type {string}
   */
  separator: string;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   * In this component, we want to display values for metadata 'dc.rights' and use 'dc.rights.uri' if available to
   * display it as a link.
   */
  fields: string[] = [
    'dc.rights'
  ];

  /**
   * Label i18n key for the rendered metadata
   */
  label = 'item.page.rights';
}
