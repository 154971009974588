<div class="item-page-field">
  <ds-metadata-field-wrapper [label]="label | translate">
    <ng-container *ngVar="item?.allMetadata(fields) as rights">
      <ng-container *ngFor="let right of rights; let last=last;">
        <a *ngIf="rights.length === 1 && item?.firstMetadataValue('dc.rights.uri'); else cantRenderUrl"
           [target]="target" [href]="item?.firstMetadataValue('dc.rights.uri')">
          {{right.value}}
        </a>
        <ng-template #cantRenderUrl>
          {{right.value}}
        </ng-template>
        <span *ngIf="!last" [innerHTML]="separator"></span>
      </ng-container>

      <a *ngIf="rights.length === 0 && item?.firstMetadataValue('dc.rights.uri')"
         [target]="target" [routerLink]="item?.firstMetadataValue('dc.rights.uri')">
        {{item?.firstMetadataValue('dc.rights.uri')}}
      </a>
    </ng-container>
  </ds-metadata-field-wrapper>
</div>
