import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import {
  ItemComponent
} from '../../../../../../../app/item-page/simple/item-types/shared/item.component';


/**
 * Component that represents a data-file Item page
 */

@listableObjectComponent('DataFile', ViewMode.StandalonePage, Context.Any, 'express')
@Component({
  selector: 'ds-data-file',
  styleUrls: ['./data-file.component.scss'],
  templateUrl: './data-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFileComponent extends ItemComponent {

}
