import { NgModule } from '@angular/core';
import { ENTRY_COMPONENTS as EXPRESS } from './express/entry-components';
import { ENTRY_COMPONENTS as EXPRESS_CLIENT } from './express-client/entry-components';

const ENTRY_COMPONENTS = [
  ...EXPRESS,
  ...EXPRESS_CLIENT
];


/**
 * This module only serves to ensure themed entry components are discoverable. It's kept separate
 * from the theme modules to ensure only the minimal number of theme components is loaded ahead of
 * time
 */
@NgModule()
export class ThemedEntryComponentModule {
  static withEntryComponents() {
    return {
      ngModule: ThemedEntryComponentModule,
      providers: ENTRY_COMPONENTS.map((component) => ({provide: component}))
    };
  }

}
