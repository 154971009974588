import { Component } from '@angular/core';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResultListElementComponent } from '../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';

@listableObjectComponent('OrgUnitSearchResult', ViewMode.ListElement, Context.Any, 'express')
@Component({
  selector: 'ds-org-unit-search-result-list-element',
  styleUrls: ['../../../../../shared/object-list/search-result-list-element/search-result-list-element.component.scss', './org-unit-search-result-list-element.component.scss'],
  templateUrl: './org-unit-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Organisation Unit
 */
export class OrgUnitSearchResultListElementComponent extends ItemSearchResultListElementComponent {
}
