import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { JournalSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal/journal-search-result-list-element.component';
import { JournalIssueSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-issue/journal-issue-search-result-list-element.component';
import { JournalVolumeSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-volume/journal-volume-search-result-list-element.component';
import { OrgUnitSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component';
import { PersonSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/person/person-search-result-list-element.component';
import { ProjectSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/project/project-search-result-list-element.component';
import { ItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { CommunitySearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component';
import { CollectionSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/collection-search-result/collection-search-result-list-element.component';
import { DataFileComponent } from './app/item-page/simple/item-types/data-file/data-file.component';
import {
  DataPackageComponent
} from './app/item-page/simple/item-types/data-package/data-package.component';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';

export const ENTRY_COMPONENTS = [
  PublicationComponent,
  JournalSearchResultListElementComponent,
  JournalIssueSearchResultListElementComponent,
  JournalVolumeSearchResultListElementComponent,
  OrgUnitSearchResultListElementComponent,
  PersonSearchResultListElementComponent,
  ProjectSearchResultListElementComponent,
  ItemSearchResultListElementComponent,
  CommunitySearchResultListElementComponent,
  CollectionSearchResultListElementComponent,
  DataFileComponent,
  DataPackageComponent,
  UntypedItemComponent
];
