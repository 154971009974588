import { Component } from '@angular/core';
import {
  listableObjectComponent,
  DEFAULT_CONTEXT
} from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { CommunitySearchResult } from '../../../../../../../app/shared/object-collection/shared/community-search-result.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { SearchResultListElementComponent } from '../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component';
import { Community } from '../../../../../../../app/core/shared/community.model';

@Component({
  selector: 'ds-community-search-result-list-element',
  styleUrls: ['../search-result-list-element.component.scss', 'community-search-result-list-element.component.scss'],
  templateUrl: 'community-search-result-list-element.component.html'
})
/**
 * Component representing a community search result in list view
 */
@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, DEFAULT_CONTEXT, 'express')
export class CommunitySearchResultListElementComponent extends SearchResultListElementComponent<CommunitySearchResult, Community> {

}
