import { Component } from '@angular/core';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResultListElementComponent } from '../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';

@listableObjectComponent('PersonSearchResult', ViewMode.ListElement, Context.Any, 'express')
@Component({
  selector: 'ds-person-search-result-list-element',
  styleUrls: ['../../../../../shared/object-list/search-result-list-element/search-result-list-element.component.scss', './person-search-result-list-element.component.scss'],
  templateUrl: './person-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Person
 */
export class PersonSearchResultListElementComponent extends ItemSearchResultListElementComponent {

  get name() {
    return this.value ?
      this.value :
      this.firstMetadataValue('person.familyName') + ', ' + this.firstMetadataValue('person.givenName');
  }
}
