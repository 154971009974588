<div class="d-flex flex-row items-align-stretch">
    <ds-thumbnail class="d-none d-sm-inline-block"
                  [thumbnail]="dso?.thumbnail | async">
    </ds-thumbnail>
    <div class="info flex-grow-1">
        <ds-truncatable [id]="dso.id">
            <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
            <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
               rel="noopener noreferrer"
               [routerLink]="[itemPageRoute]" class="lead item-list-title"
               [innerHTML]="dsoTitle"></a>
            <span *ngIf="linkType == linkTypes.None"
                  class="lead item-list-title"
                  [innerHTML]="dsoTitle"></span>
            <!--<span class="text-muted">-->
            <!--<ds-truncatable-part [id]="dso.id" [minLines]="1">-->
            <!--<span *ngIf="dso.allMetadata(['project.identifier.status']).length > 0"-->
            <!--class="item-list-status">-->
            <!--<span *ngFor="let value of allMetadataValues(['project.identifier.status']); let last=last;">-->
            <!--<span [innerHTML]="value"><span [innerHTML]="value"></span></span>-->
            <!--</span>-->
            <!--</span>-->
            <!--</ds-truncatable-part>-->
            <!--</span>-->
        </ds-truncatable>
    </div>
</div>
