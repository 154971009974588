import { animate, state, transition, trigger, style } from '@angular/animations';

export const focusShadow = trigger('focusShadow', [

  state('focus', style({ boxShadow: '0 0.5rem 1rem rgb(99 179 222 / 20%)' })),

  state('blur', style({ boxShadow: '0 0.5rem 1rem rgb(99 179 222 / 8%)' })),

  transition('focus <=> blur', [animate('250ms')])
]);

export const focusBackground = trigger('focusBackground', [

  state('focus', style({ backgroundColor: 'rgba(119, 119, 119, 0.1)' })),

  state('blur', style({ backgroundColor: 'transparent' })),

  transition('focus <=> blur', [animate('250ms')])
]);
