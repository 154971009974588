import { Component } from '@angular/core';
import {
  listableObjectComponent,
  DEFAULT_CONTEXT
} from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { CollectionSearchResult } from '../../../../../../../app/shared/object-collection/shared/collection-search-result.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { SearchResultListElementComponent } from '../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component';
import { Collection } from '../../../../../../../app/core/shared/collection.model';

@Component({
  selector: 'ds-collection-search-result-list-element',
  styleUrls: ['../search-result-list-element.component.scss', 'collection-search-result-list-element.component.scss'],
  templateUrl: 'collection-search-result-list-element.component.html'
})
/**
 * Component representing a collection search result in list view
 */
@listableObjectComponent(CollectionSearchResult, ViewMode.ListElement, DEFAULT_CONTEXT, 'express')
export class CollectionSearchResultListElementComponent extends SearchResultListElementComponent<CollectionSearchResult, Collection> {}
