<div class="aoc" *ngIf="objects$ | async; let objects">
  <ds-alert class="aoc-error" *ngIf="error$ | async; let error"
            [content]="error" [dismissible]="false" [type]="AlertType.Error">
  </ds-alert>
  <div class="aoc-list" *ngIf="viewMode === ViewMode.ListElement && objects.length">
    <ul class="list-unstyled">
      <li *ngFor="let object of objects; let i = index; let last = last"
          class="mt-4 mb-4 d-flex">
        <ds-listable-object-component-loader [object]="object"
                                             [viewMode]="viewMode"
                                             [index]="i"
                                             [context]="context"
                                             [linkType]="linkType"
        ></ds-listable-object-component-loader>
      </li>
    </ul>
  </div>
  <div class="aoc-grid" *ngIf="viewMode === ViewMode.GridElement && objects.length">
    <div class="card-columns row">
      <div class="card-column col col-sm-6 col-lg-4" *ngFor="let column of (objectsInColumns$ | async)" @fadeIn>
        <div class="card-element" *ngFor="let object of column">
          <ds-listable-object-component-loader [object]="object"
                                               [viewMode]="viewMode"
                                               [context]="context"
                                               [linkType]="linkType"
          ></ds-listable-object-component-loader>
        </div>
      </div>
    </div>
  </div>
  <span class="aoc-no-results" *ngIf="!(isLoading$ | async) && !objects.length && !(error$ | async)"
  >{{ noResultsMsg | translate }}</span>
</div>
<ds-loading *ngIf="isLoading$ | async" message=""></ds-loading>
