import { Component, Input } from '@angular/core';
import {
  ItemPageAbstractFieldComponent
} from '../../../../../../../../app/item-page/simple/field-components/specific-field/abstract/item-page-abstract-field.component';
import { MetadataValue } from '../../../../../../../../app/core/shared/metadata.models';

@Component({
  selector: 'ds-atmire-item-page-abstract-field',
  templateUrl: './atmire-item-page-abstract-field.component.html'
  // templateUrl: '../../../../../../../../app/item-page/simple/field-components/specific-field/item-page-field.component.html'
})
/**
 * This component is used for displaying the abstract (dc.description.abstract) of an item
 */
export class AtmireItemPageAbstractFieldComponent extends ItemPageAbstractFieldComponent {

  /**
   * Separator between each new line
   */
  @Input() lineSeparator = '<br>';

  /**
   * Replaces the linebreaks inside the metadata fields with the {@link lineSeparator}.
   * @param metadataValues The metadata values that need to be parsed
   * @return A cloned list of {@link MetadataValue}s with the replaced line breaks
   */
  addLineBreaks(metadataValues: MetadataValue[]): MetadataValue[] {
    const reformattedValuesMetadataValue: MetadataValue[] = [];
    for (const metadataValue of metadataValues) {
      const clone = { ...metadataValue } as MetadataValue;
      clone.value = clone.value?.replace(new RegExp('\n', 'g'), this.lineSeparator);
      reformattedValuesMetadataValue.push(clone);
    }
    return reformattedValuesMetadataValue;
  }
}
