import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { ItemSearchResultListElementComponent } from '../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';

@listableObjectComponent('JournalVolumeSearchResult', ViewMode.ListElement, Context.Any, 'express')
@Component({
  selector: 'ds-journal-volume-search-result-list-element',
  styleUrls: ['../../../../../shared/object-list/search-result-list-element/search-result-list-element.component.scss', './journal-volume-search-result-list-element.component.scss'],
  templateUrl: './journal-volume-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Journal Volume
 */
export class JournalVolumeSearchResultListElementComponent extends ItemSearchResultListElementComponent {
}
