<div class="d-flex flex-row items-align-stretch">
    <ds-thumbnail class="d-none d-sm-inline-block"
                  [thumbnail]="dso?.thumbnail | async">
    </ds-thumbnail>
    <div class="info flex-grow-1">
        <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
        <ds-truncatable [id]="dso.id">
            <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
               rel="noopener noreferrer"
               [routerLink]="[itemPageRoute]" class="lead item-list-title"
               [innerHTML]="dsoTitle"></a>
            <span *ngIf="linkType == linkTypes.None"
                  class="lead item-list-title"
                  [innerHTML]="dsoTitle"></span>
            <span class="text-muted">
    <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <span *ngIf="dso.allMetadata(['publicationvolume.volumeNumber']).length > 0"
                  class="item-list-journal-issues">
                    <span *ngFor="let value of allMetadataValues(['publicationvolume.volumeNumber']); let last=last;">
                        <span [innerHTML]="value"><span [innerHTML]="value"></span></span>
                    </span>
                <span *ngIf="dso.allMetadata(['publicationissue.issueNumber']).length > 0"
                      class="item-list-journal-issue-numbers">
                    <span *ngFor="let value of allMetadataValues(['publicationissue.issueNumber']); let last=last;">
                        <span> - </span><span [innerHTML]="value"><span [innerHTML]="value"></span></span>
                    </span>
                </span>
            </span>
        </ds-truncatable-part>
  </span>
        </ds-truncatable>
    </div>
</div>
